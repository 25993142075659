@font-face {
  /* http://www.elegantthemes.com/blog/resources/elegant-icon-font */

  font-family: "ElegantIcons";
  src: url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/fonts/ElegantIcons.eot");
  src: url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/fonts/ElegantIcons.eot?#iefix")
      format("embedded-opentype"),
    url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/fonts/ElegantIcons.woff")
      format("woff"),
    url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/fonts/ElegantIcons.ttf")
      format("truetype"),
    url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/fonts/ElegantIcons.svg#ElegantIcons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

#app {
  height: 100%;
}

#maintenance-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  font-weight: 600;
  font-size: 1.1rem;
  text-align: center;
  padding: 20px;
  z-index: 99999;
  color: red;
}

h1,
h2 {
  font-weight: 300;
  margin: 0;
  color: rgb(52, 52, 52);
}

h1 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

h2 {
  font-size: 1.8rem;
}

* {
  margin: 0;
}

p {
  margin: 20px 0;
}

html,
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5;
  direction: ltr;
  height: 100%;
  text-rendering: optimizeLegibility;
  font-synthesis: none;

  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hidden {
  display: none;
}

.icon {
  font-family: "ElegantIcons";
  display: inline-block;
}

h1,
h2,
h3,
.button {
  line-height: 1.1;
  letter-spacing: 0.02em;
}

a {
  transition: color 0.2s;
}

.no-touch a:not(.button):hover {
  color: #76b657;
}

.button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0px 15px;
  margin: 6px 6px;
  border: none;
  background-color: #76b657;
  outline: 0;
  color: #fff;
  border-radius: 0;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;

  transition: background-color 0.2s, box-shadow 0.2s, transform 0.1s;
}

.no-touch .button:hover,
.no-touch .button.secondary-button:hover {
  background-color: #83ca61;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.no-touch .button:active,
.no-touch .button.secondary-button:active {
  background-color: #659d4b;
  box-shadow: none;
  transform: scale(1);
}

.button.cancel {
  color: #333;
  font-weight: 600;
  background-color: #e4e4e4;
}

.no-touch .button.cancel:hover {
  background-color: #d7d7d7;
}

.no-touch .button.cancel:active {
  background-color: #cbcbcb;
}

.button.delete {
  background-color: #fc5e49;
}

.button.delete:hover {
  background-color: #ff775f;
}

.button.delete:active {
  background-color: #e05441;
}

/*
.button.secondary-button {
  background-color: #727272;
}

.button.login-button {
  background-color: #a1b9b9;
}

.no-touch .button.login-button:hover {
  background-color: #23a7a8;
}

.no-touch .button.login-button:active {
  background-color: #219495;
}

.button.facebook-login-button {
  background-color: #3b5998;
}

.no-touch .button.facebook-login-button:hover {
  background-color: #536cc3;
}

.no-touch .button.facebook-login-button:active {
  background-color: #394a84;
}
*/

.button.small-button {
  height: 32px;
  line-height: 32px;
  font-size: 0.7rem;
  font-weight: 600;
}

.button.tiny-button {
  height: 24px;
  line-height: 24px;
  font-size: 0.5rem;
  font-weight: 600;
  padding: 0 5px;
}

.button.simple,
.no-touch .button.simple:hover,
.no-touch .button.simple:active {
  width: 100%;
  height: auto;
  color: #4778b7;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 2;
  background: none;
  border: solid 1px #4778b7;
  border-radius: 20px;
  margin: 10px 0;
  box-shadow: none;
  transform: scale(1);

  transition: background-color 0.2s, color 0.2s;
}

.button.simple.filled,
.no-touch .button.simple.filled:hover,
.no-touch .button.simple.filled:active {
  color: #fff;
  background-color: #4778b7;
}

.button:disabled,
.no-touch .button:disabled,
.button.secondary-button:disabled,
.no-touch .button.secondary-button:disabled,
.button.facebook-button:disabled,
.no-touch .button.facebook-button:disabled {
  background-color: #d5d5d5;
  box-shadow: none;
  transform: scale(1);
}

input[type="text"],
input[type="email"],
input[type="password"] {
  height: 40px;
  border: solid 1px #bbb;
  border-radius: 3px;
  outline: 0;
  background: none;
  background-color: #fff;
  padding: 0;
  margin: 0;
  text-indent: 10px;

  transition: border-color 0.2s, box-shadow 0.2s;
}

input::-ms-clear {
  display: none;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: #808080;
  outline-color: #808080;
  outline-width: 1px;
  outline-offset: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

input[type="text"].invalid,
input[type="email"].invalid,
input[type="password"].invalid {
  border-color: red;
  outline-color: #be2828;
  outline-width: 1px;
}

select {
  background-color: white;
  border-radius: 0;
  border: 1px solid #bbb;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  text-indent: 10px;
  color: #999;
  background-image: url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/dropdown-triangle.png");
  background-position: right center;
  background-size: 30px 11px;
  background-repeat: no-repeat;
}

.subscription-status {
  font-size: 12px;
  font-weight: 600;
  color: #76b657;
  margin: 10px 0 10px;
}

.subscription-status.cancelled {
  color: #fc5e49;
}

.app {
  min-width: 320px;
  height: 100%;
}

.app.lock-scrolling {
  overflow: hidden;
}

.no-content {
  text-align: center;
  margin-top: 120px;
  color: #8e8e8e;
}

.header {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: right;
  z-index: 8;

  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.header,
.header-push {
  height: 50px;
}

.header .logo .desktop-logo,
.countdown-header .logo .desktop-logo {
  max-height: 50px;
  display: none;
}

.header .logo,
.countdown-header .logo {
  display: inline-block;
  vertical-align: top;
  float: left;
  height: 50px;
  margin-left: 10px;
  cursor: pointer;
}

.header .menu {
  float: left;
  display: inline-block;
  vertical-align: top;
}

.header .menu.right-align {
  float: none;
}

.header .header-content.desktop {
  margin: 0 10px;
  display: none;
}

.header .header-content.mobile .menu-button {
  width: 50px;
  height: 50px;
  font-size: 3rem;
  line-height: 1.1;
  float: right;
  cursor: pointer;

  -webkit-transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.3s ease-in-out;
}

.header .header-content.mobile.active .menu-button {
  -webkit-transform: rotate(-90deg);
}

.header .menu-item {
  display: inline-block;
  padding: 0 4px;
  line-height: 50px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;

  transition: color 0.2s;
}

.header .menu-item.current,
.no-touch .header .menu-item:hover {
  color: #76b657;
}

.header .language-selector {
  display: inline-block;
  float: right;
}

.header .language-selector select {
  width: auto;
  height: 50px;
  margin-right: 20px;
  padding-right: 20px;
  padding-left: 10px;
  border: none;
  background-color: transparent;
  background-position: right 28px;
  background-size: 15px 6px;
  text-indent: 0;
  outline: none;
  cursor: pointer;
}

.header .language-selector select::-ms-expand {
  display: none;
}

.header .login-menu {
  line-height: 50px;
  float: right;
}

.header .login-menu .button {
  position: relative;
  top: -2px;
}

.header .greeting {
  color: #999;
  font-weight: 600;
  padding: 0 10px;
  vertical-align: top;
  float: left;
  display: none;
}

.header .header-content.mobile .menu {
  width: 100%;
  display: block;
  background-color: #fff;
  position: absolute;
  top: -100px;
  opacity: 0;
  z-index: -1;

  transition: top 0.3s, opacity 0.3s;
}

.header .header-content.mobile .menu.loaded {
  top: 50px;
  opacity: 1;
}

.header .header-content.mobile .menu-item {
  display: block;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  border-top: 1px solid #bbb;
}

.no-touch .header .header-content.mobile .menu-item:active {
  background-color: #333;
  color: #eee;
}

.header .header-content.mobile {
  height: 50px;
  position: relative;
}

.mobile-menu-enter {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  transition: -webkit-transform 0.3s ease-out, opacity 0.3s linear;
}

.mobile-menu-enter.mobile-menu-enter-active {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
}

.mobile-menu-leave {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transition: -webkit-transform 0.3s ease-out, opacity 0.3s linear;
}

.mobile-menu-leave.mobile-menu-leave-active {
  opacity: 0;
  -webkit-transform: translate3d(0, -100%, 0);
}

.page-wrap {
  min-height: 100%;
  height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
  height: 100%;
  margin: 0 auto;
}

.inner-page-wrap {
  padding: 0 25px;
  margin: 0 auto;
}

.inner-page-wrap.breadcrumbs {
  padding: 0 10px;
}

.footer-container {
  font-size: 0.8rem;
  text-align: center;
  background-color: #f1f1f1;
  padding: 20px;
}

.modal {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}

.modal.loaded {
  opacity: 1;
  display: block;
}

.modal.hidden {
  display: none;
}

.modal p {
  margin: 10px 0;
}

.modal .content {
  position: relative;
  display: inline-block;
  margin: 25px;
  padding: 25px;
  background-color: #f5f5f5;
  text-align: left;
  border-radius: 6px;
}

.modal > .content {
  top: -50px;
  transition: top 0.6s;
}

.modal.loaded > .content {
  top: 0;
}

.modal h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.modal .close {
  font-size: 1.5rem;
  line-height: 1;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 1;

  transition: box-shadow 0.2s, transform 0.2s;
}

.no-touch .modal .close:hover {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: scale(1.4);
}

.no-touch .modal .close:active {
  box-shadow: none;
  transform: scale(1);
}

.modal .content > img {
  max-width: 100%;
  max-height: 100%;
}

.voucher-page-component {
  text-align: center;
}

.voucher-page-component .background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;
}

.voucher-page-component h2 {
  padding-top: 10px;
  padding-bottom: 10px;
  /*text-transform: uppercase; REMOVE ONLY FROM FALCK CUSTOM STYLE */
  /*color: #efefef; ADD TO KRIMIFILM CUSTOM STYLES*/
}

.voucher-page-component .description {
  padding-bottom: 10px;
  color: #8e8e8e;
}

.finale-page,
.blocked-page {
  text-align: center;
  background-repeat: no-repeat;
  background-position: center top;
  color: #fff;
}

.blocked-page {
  color: inherit;
}

.finale-page h2,
.blocked-page h2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.finale-page .title,
.blocked-page .title {
  font-size: 2.8rem;
}

.finale-page .description,
.blocked-page .description {
  font-size: 1.8rem;
}

.countdown-page .countdown-banner-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.countdown-page .countdown-banner {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90%;
  -webkit-animation: fade-in 750ms ease-in 0;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.countdown-page .countdown-header {
  position: fixed;
  width: 100%;
  height: 70px;
  top: 0;
  background-color: #fff;
  text-align: center;
}

.countdown-page .countdown-header-content {
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.countdown-page .countdown-header .logo {
  display: block;
  float: left;
  clear: left;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}

.countdown-page .countdown-expiration-text {
  text-align: center;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0.8rem;
}

.countdown-page .countdown-expiration-text span {
  white-space: nowrap;
  display: inline-block;
  margin: 2px;
}

.rotating-banner-wrapper {
  position: relative;
}

.rotating-banner-wrapper .radio-buttons {
  position: absolute;
  bottom: 10px;
  left: 50%;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  z-index: 5;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
}

.rotating-banner-wrapper .radio-buttons .radio-button {
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  -webkit-transition: background-color 150ms ease;
  -moz-transition: background-color 150ms ease;
  transition: background-color 150ms ease;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.rotating-banner-wrapper .radio-buttons .radio-button.selected {
  background-color: rgba(255, 255, 255, 0.8);
}

.no-touch .rotating-banner-wrapper .radio-buttons .radio-button:active {
  background-color: rgba(255, 255, 255, 1);
}

.rotating-banner-wrapper .rotating-banner {
  background: #1a1a1a;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.rotating-banner-wrapper .rotating-banner.no-click {
  cursor: default;
}

.rotating-banner-wrapper .rotating-banner img {
  display: block;
  width: 100%;
}

.rotating-banner-wrapper .rotating-banner img.rotating-banner-enter {
  opacity: 0.001;
  transition: opacity 0.7s ease;
}

.rotating-banner-wrapper
  .rotating-banner
  img.rotating-banner-enter.rotating-banner-enter-active {
  opacity: 1;
}

.rotating-banner-wrapper .rotating-banner img.rotating-banner-leave {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  opacity: 1;
  transition: opacity 0.7s ease;
}

.rotating-banner-wrapper
  .rotating-banner
  img.rotating-banner-leave.rotating-banner-leave-active {
  opacity: 0.001;
}

.rotating-banner-wrapper .purchase {
  width: 500px;
  text-align: center;
  margin-left: -250px;
  position: absolute;
  top: 25%;
  left: 50%;
  z-index: 6;
}

.rotating-banner-wrapper .purchase h2 {
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
}

.rotating-banner-wrapper .purchase .button {
  width: 320px;
}

.still-banner-wrapper {
  position: relative;
}

.still-banner-wrapper .button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}

.still-banner-wrapper .banner-img {
  width: 100%;
  display: block;
  visibility: hidden;
}

.still-banner-wrapper .banner-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: auto 100%;
  background-position: center center;
}

.still-banner-wrapper .banner-img.desktop,
.still-banner-wrapper .banner-bg.desktop {
  display: none;
}

.breadcrumbs {
  font-size: 0.9rem;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
  padding-top: 5px;
  padding-bottom: 5px;
  overflow: hidden;
}

.breadcrumbs ul {
  list-style: none;
  padding: 0;
}

.breadcrumbs ul li {
  color: #777;
  padding: 5px;
  margin-right: -5px;
  float: left;
}

.breadcrumbs ul li:not(:last-child)::after {
  font-family: "ElegantIcons";
  content: "5";
  position: relative;
  top: 1px;
}

.breadcrumbs ul li a {
  border-radius: 4px;
  padding: 5px;
  text-decoration: none;

  transition: color 0.2s, background-color 0.2s;
}

.no-touch .breadcrumbs ul li a:hover,
.breadcrumbs ul li:last-child a {
  color: #76b657;
}

.content-overview {
  overflow: hidden;
}

.content-overview.filters {
  margin-top: 40px;
}

.content-overview .content-details.compact.type-facing {
  display: inline-block;
  vertical-align: top;
  width: 180px;
  margin-bottom: 20px;
  white-space: normal;
}

.content-overview .content-details.compact.bundle,
.content-overview .content-details.compact.subscription {
  width: 300px;
}

.content-overview .content-details.compact.type-banner {
  text-align: left;
  padding-bottom: 25px;
}

.content-overview .content-details.compact.type-banner .breadcrumbs {
  display: none;
}

.compact.type-facing {
  cursor: pointer;
}

.compact.type-facing a {
  width: 100%;
  height: 0;
  /* Trick to have a percentage based height of content - this will follow width percentage - works like an aspect ration */
  padding-top: 150%;
  overflow: hidden;
  display: block;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  background-color: #dfdfdf;
  border-radius: 4px;
  opacity: 0.9;

  transition: opacity 0.1s, box-shadow 0.2s, transform 0.1s;
}

.compact.type-facing a img {
  width: 100%;
  visibility: hidden;
}

.compact.bundle a,
.compact.subscription a {
  background-size: contain;
  padding-top: 76%;
}

.compact.type-facing .title {
  color: #777;
  font-weight: 400;
  text-align: center;
  font-size: 0.874rem;
  padding: 5px 0;
  position: relative;
  top: 0;

  transition: color 0.1s, top 0.1s;
}

.compact.type-facing .title .terms {
  font-weight: 300;
  opacity: 0.6;
}

.no-touch .compact.type-facing:hover .title,
.subscription-signup.single .signup .compact.type-facing .title {
  color: inherit;
  top: 10px;
}

.no-touch .compact.type-facing:hover a,
.subscription-signup.single .signup .compact.type-facing a {
  opacity: 1;
  box-shadow: 0 30px 30px -30px rgba(0, 0, 0, 0.5);

  transform: scale(1.08);
}

.compact.type-facing .action {
  position: relative;
  top: 0;

  transition: top 0.1s;
}

.no-touch .compact.type-facing:hover .action,
.subscription-signup.single .signup .compact.type-facing .action {
  top: 10px;
}

.compact .still-banner-wrapper .banner-img.desktop,
.still-banner-wrapper .banner-bg.desktop {
  display: none;
}

.compact .still-banner-wrapper .banner-img.mobile,
.still-banner-wrapper .banner-bg.mobile {
  display: inline-block;
}

.content-overview .content-overview-banner {
  display: none;
}

.content-overview .content-overview-inner-page-wrap {
  padding: 25px 0 25px 25px;
  text-align: center;
}

.content-overview .content-overview-inner-page-wrap.grouped,
.account-page-wrap {
  max-width: 1200px;
  padding: 50px;
  text-align: left;
  margin: 0 auto;
}

.devices {
  margin-top: 15px;
}

.devices p {
  color: #888;
  font-size: 0.8rem;
  margin: 5px 0;
}

.affiliate-links {
  color: #888;
  font-size: 0.8rem;
}

.video-placeholder-wrapper {
  position: relative;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 0;
  margin: 0;
}

.video-placeholder-wrapper .video-placeholder-play,
.account-page .active-content .content-facing-wrapper .content-facing-play {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 51px 51px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("//s3-eu-west-1.amazonaws.com/test.contentbase.dk/play.png");
  cursor: pointer;
}

.video-placeholder-wrapper .video-placeholder-img {
  width: 100%;
  border-radius: 4px;
}

.player-page {
  color: #777;
  font-size: 0.9rem;
  font-weight: 500;
}

.player-page .video {
  height: 400px;
  background: #000;
  margin: 0 -25px;
}

.player-page .video #video-player {
  min-width: 100%;
  min-height: 100%;
  margin-bottom: -6px;
}

.player-page .nav {
  border-bottom: dotted 1px #f1f1f1;
  margin: 0 -25px;
  padding: 25px;
}

.player-page .nav a {
  border: solid 1px transparent;
  border-radius: 4px;
  text-decoration: none;
  padding: 10px 20px 10px 10px;
  position: relative;
  top: -2px;

  transition: color 0.2s, border-color 0.2s, background-color 0.2s;
}

.no-touch .player-page .nav a:hover {
  border-color: #f1f1f1;
}

.no-touch .player-page .nav a:active {
  color: inherit;
  border-color: #ddd;
  background-color: #ddd;
}

.player-page .nav a .icon {
  font-size: 1rem;
  position: relative;
  top: 2px;
}

.cookie-permission-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 140px;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 0.65rem;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: height 150ms;

  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.cookie-permission-banner-content {
  text-align: center;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 25px;
}

.cookie-permission-text {
  display: inline-block;
  line-height: 0.9rem;
  vertical-align: middle;
  max-width: 640px;
}

.cookie-permission-banner .button.cookie-permission-dismiss-button {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
}

.cookie-permission-banner .read-more-link {
  display: inline-block;
}

.link {
  color: #808080;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}

.text-field-wrapper,
.dropdown-wrapper {
  display: inline-block;
  width: 100%;
}

.text-field-description,
.dropdown-description {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
}

.text-field-extended-description {
  margin-left: 5px;
  font-weight: 300;
}

.text-field-error-message,
.dropdown-error-message {
  display: none;
  font-size: 0.6rem;
  color: red;
  min-height: 13px;
  line-height: 13px;
}

.text-field-wrapper.invalid .text-field-description,
.dropdown-wrapper.invalid .dropdown-description {
  display: none;
}

.text-field-wrapper.invalid .text-field-error-message,
.dropdown-wrapper.invalid .dropdown-error-message {
  display: block;
}

.text-field-wrapper input,
.dropdown-wrapper input {
  display: block;
  clear: both;
  width: 100%;
}

.signup .question {
  margin-top: 5px;
}

.signup .field-conditions {
  font-size: 0.7rem;
  line-height: 0.95rem;
  color: #939393;
  margin-top: 5px;
}

.signup .show-more-link {
  padding: 0 2px;
}

/* Overview filter */

.filters-menu {
  width: 100%;
  background: rgba(245, 245, 245, 0.9);
  position: fixed;
  top: 50px;
  z-index: 7;

  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.overview-filter {
  width: 100%;
  max-height: 250px;
  color: #707070;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  padding: 10px;
  transform-origin: top;
  transform: perspective(500px) rotateX(-0.25turn);
  position: absolute;
  top: 50px;
  z-index: 6;
  opacity: 0;
  overflow: auto;

  transition: transform 0.4s;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.overview-filter.show {
  opacity: 1;
  transform: none;
}

.overview-filter .genres,
.overview-filter .filters {
  display: none;
}

.overview-filter.show .genres.selected,
.overview-filter.show .filters.selected {
  display: block;
}

.overview-filter .genres > div,
.overview-filter .filters > div {
  border: solid 1px #707070;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 4px;
  float: left;
  cursor: pointer;

  transition: color 0.1s, border-color 0.1s;
}

.no-touch .overview-filter .genres > div:hover,
.no-touch .overview-filter .filters > div:hover {
  color: #fff;
  border-color: #fff;
}

.overview-filter .genres > div.selected,
.overview-filter .filters > div.selected,
.no-touch .overview-filter .genres > div.selected,
.no-touch .overview-filter .filters > div.selected {
  color: #fff;
  border-color: #fff;
}

.search-menu {
  text-align: center;
}

.search-menu > div {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding: 16px 20px;
  margin-right: 5px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;

  transition: color 0.1s, background-color 0.1s, margin-right 0.1s;
}

.search-menu > div > .icon {
  margin-top: 1px;
  position: absolute;
}

/* Temp: Design experiment */

.overview-filter {
  top: 40px;
  font-size: 0.9rem;
  padding: 5px;
}

.search-menu > div {
  font-size: 0.9rem;
  padding: 9px 30px 10px 20px;
}

.search-menu .search-field input[type="text"] {
  height: 30px;
}

/* End of temp */

.search-menu > div.selected {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
}

.search-menu > div.active {
  color: #5aa11d;
}

.search-menu > div:last-child {
  margin-right: 0;
}

.search-menu .search-field {
  width: auto;
  background: none;
  padding: 2px 0;
  margin-left: 10px;
  cursor: text;
  position: relative;
}

.search-menu .search-field .icon {
  font-size: 0.8rem;
  top: 11px;
  right: 5px;
}

.search-menu .search-field .text-field-description,
.search-menu .search-field .text-field-error-message {
  display: none;
}

.search-menu .search-field input[type="text"] {
  width: 50px;
  text-indent: 0;
  background: none;
  border-color: transparent;
  box-shadow: none;
  padding-right: 20px;
  transition: 300ms ease;
}

.search-menu .search-field.expanded input[type="text"],
.search-menu .search-field input[type="text"]:focus {
  width: 320px;
  text-indent: 10px;
  background-color: #fff;
  border-color: #bbb;
}

.search-menu .search-field input[type="text"]::-webkit-input-placeholder {
  color: black;
}

/* Details */

.content-details .content-header {
  display: inline-block;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-details .content-header .facing {
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  display: block;
}

.content-details .content-header .element {
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 10px;
  margin-top: 5px;
  color: #444;
  vertical-align: top;
}

.content-details .content-inner {
  clear: both;
}

.content-details .main-metadata .key p {
  margin: 0;
}

.content-details .main-metadata .key {
  display: block;
  padding-right: 4px;
  color: #999;
  font-size: 0.7rem;
}

.content-details .main-metadata .value {
  display: inline;
  text-transform: none;
}

.content-details .main-metadata .value .item {
  display: inline;
}

.content-details .action .description {
  color: #999999;
  margin-top: 3px;
  font-size: 0.8rem;
  max-width: 660px;
  line-height: normal;
}

.content-details .action .title {
  font-size: 1.8rem;
  line-height: normal;
}

.content-details .action .button {
  width: 100%;
  margin: 15px 15px 0 0;
  display: inline-block;
}

.content-details .top-actions .action .button {
  margin: 0 0 15px;
}

.content-overview .content-details .action .button,
.content-details .related .action .button {
  width: 100%;
}

.content-details .content-inner .sub-metadata-title {
  font-weight: normal;
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: rgba(56, 56, 56, 1);
}

.content-details .content-inner .sub-metadata {
  min-height: 220px;
}

.content-details .content-inner .sub-metadata .trailer,
.content-details .content-inner .sub-metadata .still {
  width: 100%;
}

.still-img {
  width: 100%;
}

.content-details .content-inner .sub-metadata .synopsis {
  margin-top: 15px;
  padding-bottom: 15px;
  font-size: 1rem;
}

.content-details .content-inner .sub-metadata .synopsis i,
.content-details .content-inner .sub-metadata .synopsis p {
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
}

.content-details .content-inner .sub-metadata .synopsis p {
  padding-bottom: 15px;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.content-overview .content-details {
  max-width: 1000px;
  padding-right: 25px;
  margin: 0 auto;
}

.content-overview .content-details.type-facing:last-child {
  padding-right: 0;
}

.content-overview .content-details .banner {
  display: block;
  cursor: pointer;
}

.content-overview .content-details .inner-page-wrap {
  background-color: white;
  padding: 0 45px;
  padding-bottom: 25px;
}

.content-group .icon {
  position: relative;
  top: 5px;
}

.content-group-container {
  position: relative;
}

.content-group-container .sliders {
  opacity: 0;

  transition: opacity 0.2s;
}

.no-touch .content-group-container:hover .sliders {
  opacity: 1;
}

.content-group-container .sliders > span {
  width: 50px;
  font-family: "ElegantIcons";
  font-size: 3rem;
  color: #ccc;
  text-align: center;
  display: block;
  position: absolute;
  top: 40%;
  right: -50px;
  cursor: pointer;

  transition: color 0.1s;
}

.content-group-container .sliders > span:before {
  content: "\35";
}

.content-group-container .sliders > span.back {
  left: -50px;
}

.content-group-container .sliders > span.back:before {
  content: "\34";
}

.no-touch .content-group-container .sliders > span:hover {
  color: #777;
}

.content-group {
  overflow: hidden;
}

.content-group h2 {
  margin-left: 6px;
}

.content-group .items {
  padding: 11px 25px 0;
  margin: 0 -17px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  -webkit-overflow-scrolling: touch;
}

.content-group .items .subscription-status {
  text-align: center;
}

.content-group.legacy .items {
  overflow: hidden;
}

.content-group.expanded .items {
  white-space: normal;
  overflow: visible;
}

.content-group .items div:first-child {
  padding-left: 0;
}

.content-group.expanded h2 {
  margin: 0 0 10px 6px;
}

.content-group h2 a {
  color: inherit;
  font-weight: 300;
  text-decoration: none;
}

.content-group .sorting {
  color: #999;
  font-size: 0.9rem;
  background: none;
  padding: 0;
  margin: -20px 0 10px 6px;
  cursor: default;
  overflow: hidden;
}

.content-group .sorting div {
  margin-top: 11px;
  float: left;
}

.content-group .sorting select {
  width: 200px;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  float: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}

.content-details-toggle-enter {
  opacity: 0.01;
  transition: opacity 0.5s ease-in;
}

.content-details-toggle-enter.content-details-toggle-enter-active {
  opacity: 1;
}

.content-details-toggle-leave {
  opacity: 1;
  transition: opacity 0.01s ease-in;
}

.content-details-toggle-leave.content-details-toggle-leave-active {
  opacity: 0.01;
}

/* Related */

.content-details .content-header .main-metadata .number-identifier {
  color: #999;
}

.related-container h2 {
  margin-bottom: 5px;
}

.related {
  background: rgba(0, 0, 0, 0.92);
  margin: 0px -25px 25px;
  white-space: nowrap;
  overflow-x: auto;

  -webkit-overflow-scrolling: touch;
}

.related .container {
  padding: 25px 0 25px 25px;
}

.related .container .content {
  color: #707070;
  margin-right: 25px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  transition: color 150ms ease;
}

.no-touch .related .container .content:hover {
  color: #fff;
}

.related .container .content.back {
  border-right: solid 1px #2a2a2a;
  padding-right: 25px;
}

.related .container .content:last-child {
  border: none;
  padding: 0;
}

.related .container .content .banner {
  height: 200px;
  opacity: 0.9;
  overflow: hidden;
  text-align: center;

  transition: opacity 0.1s, box-shadow 0.2s, transform 0.1s;
}

.no-touch .related .container .content:hover .banner {
  opacity: 1;
  box-shadow: 0 30px 30px -30px rgba(0, 0, 0, 0.5);

  transform: scale(1.08);
}

.related .container .content .banner img {
  height: 100%;
  margin-bottom: 5px;
  border-radius: 4px;
}

.related .container .content .type {
  font-size: 0.9rem;
  color: #bebebe;
  margin-top: 5px;
  display: block;
}

.related .container .content .title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/* Assets */

.assets .download-all-button {
  margin-left: 20px;
}

.assets .tags-wrapper {
  margin-top: 5px;
}

.assets .tags-wrapper .title {
  display: inline-block;
}

.assets .tags-wrapper .items {
  display: inline-block;
  margin-left: 10px;
}

.assets .tags-wrapper .item {
  display: inline-block;
  vertical-align: top;
  max-width: 320px;
  font-size: 0.75rem;
  color: #555;
  border: 1px solid #999;
  border-radius: 4px;
  padding: 2px 5px;
  margin-right: 5px;
  cursor: pointer;

  transition: color 0.2s, border-color 0.2s, background-color 0.2s;
}

.no-touch .assets .tags-wrapper .item:hover {
  color: #333;
  border-color: #999;
  background-color: #ddd;
}

.no-touch .assets .tags-wrapper .item:active,
.no-touch .assets .tags-wrapper .item.selected:active {
  color: #fff;
  border-color: #666;
  background-color: #666;
}

.assets .tags-wrapper .item.selected {
  color: #fff;
  border-color: #999;
  background-color: #999;
}

.assets .asset-categories {
  margin-top: 15px;
}

.assets .asset-category {
  margin-top: 30px;
  margin: 10px 0.6%;
  margin-bottom: 10px;
  background-color: #f7f7f7;
  padding: 25px;
  padding-right: 0px;
}

.assets .asset-category .empty {
  color: #666;
  font-size: 0.85rem;
}

.asset-category-header {
  position: relative;
  margin-right: 20px;
}

.assets .asset-category-title {
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
}

.assets .asset-category-count {
  display: inline-block;
  margin-left: 20px;
  font-size: 0.7rem;
  vertical-align: middle;
  position: absolute;
  top: 7px;
  right: 0px;
}

.asset-category-show-all-button {
  display: none;
}

.assets .asset-category-assets {
  margin-top: 10px;
}

.assets .asset {
  width: 15.7%;
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  margin-top: 30px;
}

.assets .asset-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  margin-bottom: 2px;
  white-space: nowrap;
}

.assets .asset-subtitle {
  font-size: 0.8rem;
  margin-bottom: 2px;
  font-style: italic;
}

.assets .asset-thumbnail {
  width: 100%;
  height: 160px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #eee;
  background-image: url("//s3-eu-west-1.amazonaws.com/ui.contentbase.dk/document-icon.png");
}

.assets .asset .button {
  display: inline-block;
  width: 49%;
  margin: 0;
  outline: none;
}

.assets .asset .asset-actions {
  overflow: hidden;
}

.assets .asset .button:last-child {
  float: right;
}

.assets .asset .button:first-child {
  float: left;
}

.assets .asset .tags {
  margin-top: 2px;
}

.assets .asset .tags .item {
  display: inline-block;
  vertical-align: top;
  max-width: 320px;
  font-size: 0.75rem;
  padding: 1px 3px;
  border: 1px solid #aaa;
  margin-right: 3px;
  border-radius: 4px;
}

.assets .territories {
  margin-top: 2px;
}

.assets .asset .flag-wrapper {
  display: inline-block;
  margin-right: 4px;
}

.login {
  width: 100%;
  margin: 160px auto 0px;
}

.login .background {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;
}

.login.continue-step {
  padding-bottom: 120px;
  margin-top: 70px;
}

.modal .login {
  margin-top: 0;
}

.modal .login .background {
  display: none;
}

.login .section.facebook-section .helper-text {
  font-size: 0.8rem;
}

.login .section.facebook-section .button {
  width: 100%;
  margin-left: 0;
}

.login .section-seperator {
  margin: 20px 0;
}

.login .section-content {
}

.login .no-account-link,
.reset-password {
  font-size: 0.8rem;
}

.login .username-section .text-field-wrapper,
.login .username-section input {
  width: 100%;
}

.login .username-section .button {
  margin-left: 0;
  margin-bottom: 1px;
}

.login.continue-step .button {
  float: right;
  margin-right: 0px;
  margin-top: 70px;
}

.signup {
  max-width: 320px;
  margin: 0 auto;
}

.signup .question .question-header {
  margin-bottom: 3px;
  display: none;
}

.signup .question .field {
  display: block;
  margin-top: 5px;
}

.signup .question .field input[type="text"],
.signup .question .field input[type="email"],
.signup .question .field input[type="password"] {
  width: 100%;
}

.validation-error-reason {
  color: red;
  font-size: 0.6rem;
}

.signup .field-conditions input[type="checkbox"] {
  position: absolute;
  height: 20px;
}

.signup .field-conditions .conditions-text {
  margin-left: 20px;
  max-width: 283px;
  vertical-align: top;
}

.signup input[type="button"] {
  margin-top: 20px;
  width: 100%;
}

.signup .has-account-link {
  font-size: 0.8rem;
}

.falck-permission-page {
  max-width: 320px;
  padding-bottom: 130px;
}

.falck-permission-page .fields {
  line-height: 0;
}

.falck-permission-page .conditions {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 0.9rem;
  line-height: normal;
}

.falck-permission-page .text-field-wrapper {
  margin-top: 10px;
}

.falck-permission-page .validation-error-reason {
  color: red;
  font-size: 0.6rem;
}

.falck-permission-page .conditions input[type="checkbox"] {
  position: absolute;
  height: 20px;
}

.falck-permission-page .conditions .conditions-text {
  margin-left: 20px;
  vertical-align: top;
}

.falck-permission-page .button {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.falck-permission-page p {
  margin-top: 20px;
  margin-bottom: 20px;
}

.falck-permission-page ol {
  text-indent: 0px;
  padding: 0;
}

.falck-permission-page .description {
  color: #444;
  font-size: 0.9rem;
}

.text-field-wrapper.width-1,
input.width-1,
.width-1 select,
.field.width-1 {
  width: 100%;
}

.text-field-wrapper.width-2,
input.width-2,
.width-2 select,
.field.width-2 {
  width: 100%;
}

.text-field-wrapper.width-3,
input.width-3,
.width-3 select,
.field.width-3 {
  width: 100%;
}

.text-field-wrapper.width-4,
input.width-4,
.width-4 select,
.field.width-4 {
  width: 100%;
}

.inner-page-wrap.page-404,
.inner-page-wrap.about-page,
.inner-page-wrap.support-page,
.inner-page-wrap.conditions-page {
  max-width: 650px;
  font-size: 0.9rem;
  margin-top: 25px;
}

.page-404 .page-wrap h2,
.about-page .page-wrap h2,
.support-page .page-wrap h2,
.conditions-page .page-wrap h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.page-404 .page-wrap h3,
.about-page .page-wrap h3,
.support-page .page-wrap h3,
.conditions-page .page-wrap h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

.page-404 .page-wrap h4,
.about-page .page-wrap h4,
.support-page .page-wrap h4,
.conditions-page .page-wrap h4 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.page-404 .page-wrap h5,
.about-page .page-wrap h5,
.support-page .page-wrap h5,
.conditions-page .page-wrap h5 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 1rem;
}

.page-404 table,
.about-page table,
.support-page table,
.conditions-page table {
  width: 100%;
}

.page-404 td,
.about-page td,
.support-page td,
.conditions-page td {
  padding: 5px;
}

.page-404 p,
.about-page p,
.support-page p,
.conditions-page p {
  margin: 1rem 0;
}

.page-404 ul,
.about-page ul,
.support-page ul,
.conditions-page ul {
  margin: 10px 0 20px 0;
}

.breadcrumbs ul {
  margin: 0;
}

#payment-window {
  position: relative;
  overflow: hidden;
}

#payment-div {
  height: 160px;
  opacity: 0;
  transition: opacity 0.4s;
}

#payment-div.small {
  height: 60px;
}

#payment-div.loaded {
  opacity: 1;
}

.payment .validation-error-reason {
  margin-left: 18px;
  margin-bottom: -15px;
}

.payment .content .order,
.payment .content .user-data,
.payment .content .conditions,
.payment .content .payment-button {
  margin-bottom: 20px;
}

.payment .gutter,
.complete-signup .gutter,
.payment-receipt .gutter {
  float: left;
}

.payment .gutter .facing img,
.complete-signup .gutter .facing img,
.payment-receipt .gutter .facing img {
  width: 155px;
  border-radius: 4px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
  display: none;
}

.payment .content,
.complete-signup .content,
.payment-receipt .content {
  font-size: 12px;
  width: 100%;
  margin: 0px;
  padding: 0px;
  float: left;
}

.payment-receipt .content {
  text-align: center;
  margin-top: 40px;
}

.payment .content .order {
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 25px;
}

.payment .content .order .title h2,
.complete-signup .content h2,
.payment-receipt .content h2 {
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.complete-signup .content h2,
.payment-receipt .content h2 {
  text-transform: none;
}

.payment-receipt .content h2 {
  font-size: 20px;
}

.payment-receipt .content .receipt-text {
  color: #808080;
  font-size: 13px;
  margin: 10px 0px 30px;
}

.complete-signup .content p,
.payment-receipt .content p {
  margin: 0;
}

.complete-signup .content .description {
  color: #808080;
  border-bottom: solid 1px #ebebeb;
  margin-top: 5px;
  padding-bottom: 25px;
}

.payment .content .order .metadata,
.payment .content .order .price {
  font-size: 11px;
  color: #808080;
}

.payment .content .order .metadata {
  margin-bottom: 20px;
}

.payment .content .order .metadata.compact {
  margin-bottom: 0;
}

.payment .content .order .price .total {
  font-size: 12px;
  font-weight: 600;
  color: #333333;
}

.payment .content .description {
  margin-bottom: 20px;
}

.payment .content .terms .question-header {
  display: none;
}

.payment .content .terms input[type="checkbox"],
.payment .content .newsletter input[type="checkbox"] {
  position: relative;
  top: 15px;
}

.payment .content .terms .conditions-text,
.payment .content .newsletter .conditions-text {
  color: #808080;
  margin-left: 20px;
}

.payment .content .payment-button .button,
.complete-signup .content .button,
.payment-receipt .content .button {
  width: 100%;
  font-size: 18px;
  margin: 0;
}

.payment .content .field.width-1,
.payment .content .field.width-2,
.payment .content .field.width-3,
.payment .content .text-field-wrapper.width-2,
.payment .content input[type="text"],
.complete-signup .content .field.width-1,
.complete-signup .content .field.width-2,
.complete-signup .content .field.width-3,
.complete-signup .content .text-field-wrapper.width-2,
.complete-signup .content input[type="text"],
.payment-receipt .content .field.width-1,
.payment-receipt .content .field.width-2,
.payment-receipt .content .field.width-3,
.payment-receipt .content .text-field-wrapper.width-2,
.payment-receipt .content input[type="text"] {
  width: 100%;
}

.payment .content .question-header,
.complete-signup .content .question-header,
.payment-receipt .content .question-header {
  display: none;
}

.payment-receipt .content .button {
  width: auto;
}

.complete-signup .content .button {
  margin-top: 20px;
}

.payment .epay-footer {
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #808080;
  border-top: solid 1px #808080;
  padding: 25px;
  margin: -25px;
  margin-top: 5px;
  float: left;
  clear: left;
}

.payment .epay-footer .payment-cards {
  margin: 10px 0 10px;
}

.payment .epay-footer .payment-cards img {
  margin-right: 10px;
}

.payment .epay-footer .powered-by-contentbase {
  margin: 0;
}

.payment .dynamic-signup-fields .text-field-wrapper,
.complete-signup .dynamic-signup-fields .text-field-wrapper,
.payment-receipt .dynamic-signup-fields .text-field-wrapper {
  margin-top: 20px;
}

.payment .signup-link {
  padding-top: 5px;
}

.payment .payment-method {
  margin-bottom: 20px;
}

.payment .payment-method > .description,
.payment .discount-voucher > .description {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 4px;
  display: block;
}

.payment .payment-method > div {
  background-color: #eee;
  border: solid 1px #bbb;
  border-left: 0;
  padding: 10px 8px;
  cursor: pointer;
  display: inline-block;

  transition: color 0.1s, font-weight 0.1s, background-color 0.1s,
    border-color 0.1s;
}

.payment .payment-method > div:nth-child(2) {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: solid 1px #bbb;
}

.payment .payment-method > div:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.no-touch .payment .payment-method > div:hover {
  background: #ddd;
}

.payment .payment-method > div.selected,
.no-touch .payment .payment-method > div.selected {
  color: #fff;
  font-weight: 600;
  background-color: #76c2ff;
  border-color: #65a6db;
}

.payment .payment-method-description {
  color: #666;
  margin-bottom: 10px;
}

.payment .payment-type-link {
  margin-top: 10px;
}

.payment .discount-voucher {
  margin-bottom: 15px;
}

.payment .discount-voucher .discount-voucher-field {
  width: 100%;
  margin-bottom: 5px;
}

.payment .discount-voucher .discount-voucher-field input[type="text"] {
  box-sizing: border-box;
}

.payment .discount-voucher.error .discount-voucher-field input[type="text"] {
  border-color: red;
}

.payment .discount-voucher .button {
  width: 100%;
  background-color: #65a6db;
  margin: 0;
}

.no-touch .payment .discount-voucher .button:hover {
  background-color: #76c2ff;
}

.no-touch .payment .discount-voucher .button:active {
  background-color: #568ebc;
}

.payment .terms {
  margin-bottom: 10px;
}

.account-page > h2,
.account-page .account.content h2 {
  margin: 40px 0 0 6px;
}

.account-page > h2 p {
  margin: 0;
}

.account-page > h2.account-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.download-page {
  max-width: 320px;
}

.download-page h2 {
  margin-top: 0;
}

.download-page .button {
  width: 100%;
  margin: 20px 0 0;
}

.account-page .video-placeholder-wrapper {
  width: 180px;
  border-radius: 4px;
}

.account-page .no-content {
  margin: 15px 7px;
  text-align: inherit;
}

.user-profile {
  margin: 0 6px;
}

.user-profile .toolbar .user-profile-edit,
.no-touch .user-profile .toolbar .user-profile-edit:hover,
.no-touch .user-profile .toolbar .user-profile-edit:active {
  height: auto;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: inherit;
  color: #4778b7;
  background: none;
  margin: 10px 0 0;
  padding: 0;
  text-transform: capitalize;
  box-shadow: none;
  transform: scale(1);
}

.user-profile .toolbar .cancel {
  margin-left: 0;
}

.user-profile .detail .field {
  margin-top: 0px;
  margin-bottom: 10px;

  transition: margin-top 250ms ease-out;
}

.user-profile .detail.info .field:last-child {
  margin-bottom: 0;
}

.user-profile.editing-state .detail .field {
  margin-top: 8px;
}

.user-profile input[type="text"],
.user-profile input[type="email"],
.user-profile input[type="password"],
.user-profile select {
  transition: height 250ms ease-out, border-color 250ms ease-out,
    border-color 250ms ease-out, background-color 250ms ease-out,
    text-indent 250ms ease-out;
  margin-bottom: 10px;
}

.user-profile .detail input:disabled,
.user-profile .detail select:disabled {
  outline-color: transparent;
  background-color: transparent;
  border-color: transparent;
  color: #333;
  font-style: normal;
  height: 28px;
  background-image: none; /* For selects */
  text-indent: 0;
  margin-bottom: 0;
}

.user-profile .text-field-description,
.user-profile .dropdown-description {
  color: #888;
  transition: color 250ms ease-out;
}

.user-profile.editing-state .text-field-description,
.user-profile.editing-state .dropdown-description {
  color: #333;
}

/* Misc */

body,
.content-details .content-header .element,
.search-menu .search-field input[type="text"]::-webkit-input-placeholder {
  color: #555;
}

/*
body,
.content-overview .content-overview-inner-page-wrap {
  background-color: #eee;
}
*/

h1,
h2,
h3 {
  color: #000;
}

.header .menu-item.current,
.header .greeting,
.no-touch .header .menu-item:hover,
.search-menu > div.active,
.breadcrumbs ul li:last-child a,
.no-touch .breadcrumbs ul li a:hover,
.no-touch .user-profile .toolbar .user-profile-edit:active,
.no-touch .user-profile .toolbar .user-profile-edit:hover,
.user-profile .toolbar .user-profile-edit {
  color: #76b657;
}

.content-details .content-header .element.synopsis {
  margin: 25px 0;
}

.content-details .content-header .element.genre a {
  text-decoration: none;
  font-style: italic;
}

.brand {
  margin-bottom: 20px;
}

.mobile-logo {
  max-height: 50px;
}

.signup input[type="button"] {
  margin: 15px 0 0;
}

.account-page-wrap .detail.info {
  width: 160px;
}

/* Subscription Page */

.shortcut-bar {
  background-color: #f1f1f1;
  text-align: center;
  overflow: hidden;
}

.shortcut-bar ul {
  list-style: none;
  padding: 0;
  margin-bottom: -6px;
  display: inline-block;
}

.shortcut-bar ul li {
  min-width: 150px;
  border-right: solid 1px #ddd;
  padding: 20px;
  float: left;
  cursor: pointer;

  transition: background-color 0.2s;
}

.shortcut-bar ul li:first-child {
  border-left: solid 1px #ddd;
}

.shortcut-bar ul li:hover {
  background-color: #ddd;
}

.shortcut-bar ul li:active {
  background-color: #aaa;
}

.shortcut-bar ul li .icon {
  font-size: 30px;
  margin-right: 20px;
  float: left;
}

.shortcut-bar ul li .description {
  float: right;
  display: inline-block;
}

.shortcut-bar ul li .description strong {
  font-weight: 500;
}

.subscription-signup {
  background: url("https://s3-eu-west-1.amazonaws.com/ui.contentbase.dk/example-background.jpg");
  background-size: cover;
  padding: 55px;
}

.subscription-signup .container {
  max-width: 1400px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 50px;
  margin: 0 auto;
  overflow: hidden;

  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.subscription-signup .description {
  width: 60%;
  float: left;
}

.subscription-signup .description .actions {
  border-top: solid 1px #ddd;
  padding-top: 20px;
  margin-top: 20px;
}

.subscription-signup .description .actions .button {
  margin: 0;
}

.subscription-signup .signup {
  float: right;
}

.subscription-signup.multi .description {
  width: 100%;
  text-align: center;
  float: none;
}

.subscription-signup.multi .signup {
  max-width: 100%;
  padding-top: 20px;
  float: none;
}

.subscription-signup .signup .action {
  width: 100%;
}

.subscription-signup .signup .content-details.compact.type-facing {
  margin: 0;
  cursor: default;
}

.subscription-signup .content-group {
  overflow: visible;
}

.subscription-signup.single .content-group .items {
  padding: 0;
  margin: 0;
  overflow: visible;
}

.subscription-signup.multi .content-group .items {
  text-align: center;
}

.subscription-signup .content-group .compact.subscription a {
  cursor: default;
}

.subscription-signup .content-group h2,
.subscription-signup.multi .description .actions,
.subscription-signup.single .signup .action,
.subscription-signup.single .compact.type-facing .title,
.subscription-signup.single .content-group-container .sliders {
  display: none;
}

.subscription-ad,
.subscription-terms {
  border-top: solid 10px #f1f1f1;
  padding: 55px 20%;
}

.subscription-ad img {
  width: 100%;
}

/* Manage Subscriptions */
.manage-subscription input[type="button"] {
  width: 100%;
  margin: 5px 0;
}

.manage-subscription .subscription-status {
  margin-bottom: 0;
}

.payment-receipt.manage-subscription .confirmation {
  margin-top: 10px;
}

.payment-receipt.manage-subscription .button {
  width: 100%;
  margin-top: 20px;
}

/* Social */

.social {
  text-align: center;
}

.social h2,
.payment-receipt .content .social h2 {
  margin-bottom: 15px;
}

/* AddThis */

.at-resp-share-element .at-share-btn:focus,
.at-resp-share-element .at-share-btn:hover {
  transform: none !important;
}

/* Seperator */

.seperator {
  border-top: solid 1px #ddd;
  padding-top: 20px;
  margin-top: 25px;
}

/* Back to Top */
#back-to-top {
  width: 50px;
  height: 50px;
  color: #fff;
  font-size: 2.5rem;
  line-height: 1.2;
  text-align: center;
  background-color: rgba(220, 220, 220, 0.8);
  border-radius: 100%;
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  opacity: 0;

  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  transition: box-shadow 0.2s, transform 0.1s;
}

#back-to-top:after {
  content: "2";
  position: relative;
  left: -1px;
}

.no-touch #back-to-top:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
}

.no-touch #back-to-top:active {
  box-shadow: none;
  transform: scale(1);
}

@media (max-height: 420px) and (orientation: landscape) {
  .countdown-page .countdown-banner {
    background-size: 30%;
  }
}

/* TODO: Deprecate */
@media (min-width: 420px) {
  .compact .still-banner-wrapper .banner-img.desktop,
  .still-banner-wrapper .banner-bg.desktop {
    display: inline-block;
  }

  .compact .still-banner-wrapper .banner-img.mobile,
  .still-banner-wrapper .banner-bg.mobile {
    display: none;
  }

  .cookie-permission-banner .button.cookie-permission-dismiss-button {
    margin-left: 6px;
    margin-right: 6px;
  }

  .countdown-page .countdown-expiration-text {
    font-size: 1rem;
  }

  .header .menu-item {
    padding: 0 10px;
    font-size: inherit;
  }

  .login .section.facebook-section .button {
    width: auto;
  }

  .login .username-section .button,
  .login .password-section .button {
    width: auto;
  }

  .login .username-section .text-field-wrapper,
  .login .username-section input[type="email"],
  .login .password-section .text-field-wrapper,
  .login .password-section input[type="password"] {
    width: 320px;
  }

  .login .username-section .button,
  .login .password-section .button {
    width: auto;
    margin-left: 6px;
    vertical-align: bottom;
    margin-bottom: 0px;
  }

  .signup .question .field {
    display: inline-block;
  }

  .still-banner-wrapper {
    margin: 0;
    width: 100%;
  }

  .still-banner-wrapper .banner-img.mobile,
  .still-banner-wrapper .banner-bg.mobile {
    display: none;
  }

  .still-banner-wrapper .banner-img.desktop,
  .still-banner-wrapper .banner-bg.desktop {
    display: inline-block;
  }

  .content-details {
    background-position: center center;
    background-repeat: no-repeat;
  }

  .content-details .content-header .element {
    display: block;
    font-size: 0.8rem;
    margin: 0;
    margin-top: 5px;
  }

  .account-page > .content {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;
  }

  .overview-filter {
    width: 500px;
    margin-left: -270px;
    left: 50%;
  }
}

/* Desktop */
@media (min-width: 640px) {
  .content-overview .content-overview-banner {
    display: block;
  }

  .content-details .content-header .facing {
    display: block;
  }

  .cookie-permission-banner {
    height: 110px;
  }

  .header .logo .mobile-logo,
  .countdown-header .logo .mobile-logo {
    display: none;
  }

  .header .logo .desktop-logo,
  .countdown-header .logo .desktop-logo {
    display: block;
  }

  .header .header-content.mobile {
    display: none;
  }

  .header .header-content.desktop {
    display: block;
  }

  .content-details .content-header .facing {
    width: 205px;
    float: left;
    clear: left;
    margin-right: 25px;
  }

  .content-details .content-header .main-image .facing {
    float: none;
    margin: 0;
  }

  .content-details .content-inner .sub-metadata .trailer,
  .content-details .content-inner .sub-metadata .still {
    float: left;
    clear: left;
    margin-right: 20px;
    width: 280px;
  }

  .content-details .content-inner .sub-metadata .synopsis {
    margin-top: 0;
    font-size: 0.8rem;
    max-width: 900px;
  }

  .content-details .main-image,
  .content-details .main-metadata {
    float: left;
  }

  .content-details .main-image {
    padding-right: 25px;
  }

  .content-details .main-metadata {
    max-width: 60%;
  }

  .content-details.bundle .main-image .facing,
  .content-details.subscription .main-image .facing {
    width: 300px;
  }

  .content-details .action {
    line-height: 0;
  }

  .content-details .action .button {
    width: auto;
  }

  .content-details form.action .text-field-wrapper {
    margin-right: 15px;
  }

  .content-overview .content-details .action .button {
    width: 100%;
  }

  .content-details .action.stacked-layout {
    display: inline-block;
  }

  .content-details .action.stacked-layout .button {
    display: block;
    margin-left: 0;
    width: 100%;
  }

  .content-details .action input[type="text"]:first-child {
    margin-right: 6px;
  }

  .content-details .top-actions {
    display: none;
  }

  .text-field-wrapper.width-1,
  input.width-1,
  .width-1 select,
  .field.width-1 {
    width: 80px;
  }

  .text-field-wrapper.width-2,
  input.width-2,
  .width-2 select,
  .field.width-2 {
    width: 160px;
  }

  .text-field-wrapper.width-3,
  input.width-3,
  .width-3 select,
  .field.width-3 {
    width: 240px;
  }

  .modal .content {
    margin: 110px 20px;
  }

  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal #video-player {
    width: 550px;
    height: 310px;
  }

  .payment .content,
  .complete-signup .content,
  .payment-receipt .content {
    width: 300px;
    margin: 0px 20px;
  }

  .payment .epay-footer {
    width: 495px;
  }

  .payment .gutter .facing img,
  .complete-signup .gutter .facing img,
  .payment-receipt .gutter .facing img {
    display: block;
  }

  .login {
    width: 427px;
  }
}

@media (min-width: 700px) {
  .header .greeting {
    display: inline-block;
  }
}

@media (min-width: 950px) {
  .cookie-permission-banner {
    height: 90px;
  }

  .cookie-permission-banner .cookie-permission-actions {
    display: inline-block;
    vertical-align: middle;
    margin-top: 0;
    margin-left: 10px;
  }

  .cookie-permission-banner .button.cookie-permission-dismiss-button {
    width: auto;
    margin-left: 6;
    margin-right: 6px;
  }

  .countdown-page .countdown-banner {
    background-size: auto;
  }

  .modal #video-player {
    width: 800px;
    height: 450px;
  }
}

/* TODO: Deprecate */
@media (max-width: 420px) {
  .content-overview .content-overview-inner-page-wrap {
    padding: 25px;
  }

  .content-group.expanded .content-details.compact.type-facing {
    padding: 0;
  }

  .content-group.expanded .items {
    text-align: center;
  }

  .content-overview .content-details.compact.type-banner {
    padding-right: 0;
  }
}

/* TODO: Deprecate */
@media (max-width: 640px) {
  .search-menu > div,
  .content-group-container .sliders {
    display: none;
  }

  .search-menu .search-field,
  .search-menu .search-field.expanded {
    width: 100%;
    padding: 5px;
    margin: 0;
    display: block;
    box-sizing: border-box;
  }

  .search-menu .search-field input[type="text"],
  .search-menu .search-field.expanded input[type="text"],
  .search-menu .search-field input[type="text"]:focus {
    width: 100%;
    height: 30px;
    text-indent: 10px;
    background-color: #fff;
    border-color: #bbb;
    box-sizing: border-box;
  }

  .search-menu .search-field .icon {
    font-size: 1rem;
    top: 11px;
    right: 17px;
  }

  .content-overview .content-overview-inner-page-wrap.grouped,
  .account-page-wrap {
    padding: 25px;
  }

  .content-group {
    overflow: visible;
  }

  .content-group .items {
    margin: 0 -25px;
  }

  .content-overview .content-details.compact.type-facing,
  .content-group.expanded .content-details.compact.type-facing {
    width: 70%;
  }

  .user-profile .toolbar .button {
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }

  .user-profile .toolbar .user-profile-edit {
    width: auto;
  }
}

@media (max-width: 1024px) {
  .subscription-signup,
  .subscription-signup .container {
    padding: 30px;
  }
}

@media (max-width: 800px) {
  .rotating-banner-wrapper .purchase {
    display: none;
  }

  .shortcut-bar ul li {
    min-width: auto;
  }

  .shortcut-bar ul li .icon {
    margin: 0;
  }

  .shortcut-bar ul li .description {
    display: none;
  }

  .subscription-signup .description,
  .subscription-signup .signup {
    width: 100%;
    float: none;
  }

  .subscription-signup .signup {
    text-align: center;
  }

  .subscription-signup .signup .content-details.compact.type-facing {
    width: 100%;
    padding-top: 10px;
  }
}

@media (max-width: 640px) {
  .subscription-signup.multi .description {
    text-align: left;
  }

  .subscription-signup .description .actions .button {
    width: 100%;
  }

  .subscription-signup.multi .signup {
    padding-top: 0;
  }

  .subscription-signup.single .content-group-container {
    padding-top: 20px;
  }

  .subscription-ad,
  .subscription-terms {
    padding: 40px;
  }
}
